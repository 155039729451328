import gsap from 'gsap/dist/gsap';
import React, { useEffect, useRef, useState } from 'react';

import { getCSSVar, isBrowser, setCSSVar } from '../../utils/helpers';
import style from './CustomCursor.module.scss';

if (isBrowser()) {
  // Due to SSR we can only register when we are in the browser
  // gsap.registerPlugin(TextPlugin);
}

type Props = {
  // item: ChapterMediaType;
  show: boolean;
  isZoomed: boolean;
};

export const CustomCursor = ({ show, isZoomed }: Props): JSX.Element => {
  const mouseFollowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.to(mouseFollowRef.current, { autoAlpha: show ? 1 : 0 });
  }, [show]);

  useEffect(() => {
    gsap.set(mouseFollowRef.current, {
      x: getCSSVar('--mouseX'),
      y: getCSSVar('--mouseY'),
    });
    const onMouseMove = (e: MouseEvent) => {
      gsap.to(mouseFollowRef.current, {
        x: e.pageX,
        y: e.pageY - window.scrollY,
        onUpdate: () => {
          //set CSS vars for pos
          setCSSVar('--mouseX', e.pageX);
          setCSSVar('--mouseY', e.pageY - window.scrollY);
        },
      });
    };
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [mouseFollowRef]);

  const iconState = isZoomed ? style.iconIsPlus : style.iconIsMinus;
  const iconSize = isZoomed ? style.iconIsLarge : style.iconIsNormal;

  return (
    <div className={`${style.positioner}`}>
      <div className={`${style.iconHolder}`}>
        <div ref={mouseFollowRef} className={`${style.icon} ${iconState} ${iconSize}`}>
          <span className={`${style.iconLine} `}></span>
          <span className={style.iconLine}></span>
        </div>
      </div>
    </div>
  );
};
