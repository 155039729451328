import { IGetPlaiceholderReturn } from 'plaiceholder';
import React from 'react';

import { isBrowser } from '../../utils/helpers';
import { DefaultMediaBlockProps } from '../general/blocks/switchBlock/SwitchBlock';
import { ZoomableMedia } from '../zoomableMedia/ZoomableMedia';
import style from './ChapterMediaItem.module.scss';

if (isBrowser()) {
  // Due to SSR we can only register when we are in the browser
  // gsap.registerPlugin(TextPlugin);
}

export type ChapterMediaItemType = DefaultMediaBlockProps & {
  type: {
    desktop:
      | 'full'
      | 'landscapeTop'
      | 'landscapeBottom'
      | 'square'
      | 'squareCrop'
      | 'squareTopRight'
      | 'squareBottomLeft';
    phone:
      | 'full'
      | 'landscapeTop'
      | 'landscapeBottom'
      | 'square'
      | 'squareCrop'
      | 'squareTopRight'
      | 'squareBottomLeft';
  };
};
export type ChapterMediaType = { crop: boolean; isZoomable: boolean; media: ChapterMediaItemType };

type Props = {
  isActive: boolean;
  chapterMedia: ChapterMediaType[];
  backgroundColour: string;
  showCustomCursor: (show: boolean, isLarge: boolean) => void;
};

export const isSquare = ({ type }: ChapterMediaItemType) => {
  return (
    type.desktop === 'squareTopRight' ||
    type.desktop === 'squareBottomLeft' ||
    type.desktop === 'square' ||
    type.desktop === 'squareCrop'
  );
};
export const hasMultipleLandscape = (chapterMedia: ChapterMediaType[]) => {
  let result = 0;
  chapterMedia.forEach((item) => {
    if (isLandscape(item.media)) {
      result++;
    }
  });
  return result > 1;
};
export const isLandscape = ({ type }: ChapterMediaItemType) => {
  return type.desktop === 'landscapeBottom' || type.desktop === 'landscapeTop';
};
export const ChapterMediaItem = ({
  chapterMedia,
  isActive,
  backgroundColour,
  showCustomCursor,
}: Props): JSX.Element => {
  const customBg = backgroundColour ? backgroundColour : undefined;

  const getItem = () => {
    if (chapterMedia.length === 1) {
      return (
        <div className={`${style.grid} ${style.full} ${style[chapterMedia[0].media.type.desktop]}`}>
          <div
            className={`${style.singleItem} ${style.singleItemFull} ${
              style['singleItem__' + chapterMedia[0].media.type.desktop]
            } `}
          >
            {/* {chapterMedia[0].media.type.desktop} */}
            <ZoomableMedia
              isActive={isActive}
              item={chapterMedia[0]}
              showCustomCursor={showCustomCursor}
            />
          </div>
        </div>
      );
    } else {
      const gridPaddingStatus = hasMultipleLandscape(chapterMedia) ? null : style.multiGridPadding;
      return (
        <div className={`${style.multiGrid} ${gridPaddingStatus}`}>
          {chapterMedia.map((item, index) => {
            const multiItemState = hasMultipleLandscape(chapterMedia)
              ? index === 0
                ? style.doubleLandscapeTop
                : style.doubleLandscapeBottom
              : null;
            return (
              <div
                key={index}
                className={`${style.multiItem} ${style.multiItemFull}
                ${style['multiItem__' + item.media.type.desktop]}
                ${multiItemState}
                `}
              >
                <ZoomableMedia
                  isActive={isActive}
                  item={item}
                  showCustomCursor={showCustomCursor}
                />
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div
      className={`${style.mediaContainer}`}
      style={{ background: customBg ? customBg : '#333C21' }}
    >
      {getItem()}
    </div>
  );
};
