import gsap from 'gsap/dist/gsap';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useWindowSize } from '../../hooks/useWindowSize';

import { isBrowser, isDesktopWidth } from '../../utils/helpers';
import { ChapterMediaType, isLandscape, isSquare } from '../chapterMediaItem/ChapterMediaItem';
import { MediaBlock } from '../general/blocks/mediaBlock/MediaBlock';
import { DefaultMediaBlockProps } from '../general/blocks/switchBlock/SwitchBlock';
import style from './ZoomableMedia.module.scss';

if (isBrowser()) {
  // Due to SSR we can only register when we are in the browser
  // gsap.registerPlugin(TextPlugin);
}

type Props = {
  isActive: boolean;
  item: ChapterMediaType;
  showCustomCursor: (show: boolean, isLarge: boolean) => void;
  // sizes: string;
};

export const ZoomableMedia = ({ item, isActive, showCustomCursor }: Props): JSX.Element => {
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [shouldShowCustomCursor, setShouldShowCustomCursor] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  const [portraitTooTall, setPortraitTooTall] = useState<boolean>(false);

  const rectRef = useRef<HTMLDivElement>(null);
  const zoomRef = useRef<HTMLDivElement>(null);
  const mouseFollowRef = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  const router = useRouter();

  const { setHideUI, showMenu } = useAppContext();

  useEffect(() => {
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);
  }, [windowSize]);

  // const ratio = rectRef.current ? rectRef.current.width / rectRef.current.height : 1;

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (portraitTooTall) {
      setShouldShowCustomCursor(true);
      showCustomCursor(true, !!isZoomed);
    } else if (!isPortraitImage() && item.isZoomable) {
      setShouldShowCustomCursor(true);
      showCustomCursor(true, !isZoomed);
    }
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShouldShowCustomCursor(false);
    // if (portraitTooTall) {
    //   showCustomCursor(false, !!isZoomed);
    // }
    // if (!isPortraitImage() && item.isZoomable) showCustomCursor(false, !isZoomed);
  };

  useEffect(() => {
    if (!isActive) return;
    const currentlyZoomed = !!isZoomed;
    if (isPortraitImage() && portraitTooTall) {
      showCustomCursor(shouldShowCustomCursor, currentlyZoomed);
    } else if (item.isZoomable) {
      showCustomCursor(shouldShowCustomCursor, !currentlyZoomed);
    } else {
      showCustomCursor(shouldShowCustomCursor, !currentlyZoomed);
    }
    // if (portraitTooTall) {
    //   showCustomCursor(true, !!isZoomed);
    // } else if (!isPortraitImage() && item.isZoomable && isZoomed) {
    //   showCustomCursor(true, !isZoomed);
    // } else {
    //   showCustomCursor(false, !isZoomed);
    // }
  }, [isZoomed, isActive, shouldShowCustomCursor]);

  useEffect(() => {
    setHideUI(false);
  }, [router]);

  useEffect(() => {
    const zoomElement = zoomRef.current;
    if (!isDesktop || !zoomElement || !rectRef.current) return;
    const rect = rectRef.current.getBoundingClientRect();

    gsap.killTweensOf(zoomElement);
    const shouldHideUI = item.media.type.desktop === 'full' && item.isZoomable && !!isZoomed;
    setHideUI(shouldHideUI);

    if (isZoomed) {
      // FOR LANDSCAPE
      if (isLandscape(item.media)) {
        showMenu(false);
        gsap.set(zoomElement, {
          position: 'fixed',
          width: rect.width,
          height: rect.height,
          zIndex: 11,
        });
        gsap.to(zoomElement, {
          x: -rect.x,
          y: -rect.y,
          width: window.innerWidth,
          height: window.innerHeight,
        });
      } else if (item.media.type.desktop === 'full') {
        setHideUI(false);
      }
    } else {
      if (isZoomed === undefined || !rectRef.current) return;

      const originalSize = rectRef.current?.getBoundingClientRect();
      gsap.to(zoomElement, {
        x: 0,
        y: 0,
        width: originalSize.width,
        height: originalSize.height,
        onComplete: () => {
          gsap.set(zoomElement, { clearProps: 'all' });
        },
      });
    }
  }, [isZoomed]);

  const onItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isPortraitImage() && item.isZoomable) {
      setIsZoomed(!isZoomed);
    } else if (portraitTooTall) {
      setIsZoomed(!isZoomed);
    }
  };

  const isPortraitImage = () => item.isZoomable && item.media.type.desktop === 'full';
  const aspectRatioDesktop = () => item.media.image_width / item.media.image_height;

  useEffect(() => {
    // const aspectRatioDesktop = item.media.image_width / item.media.image_height;
    const predictedWidth = windowSize.width / 2;
    const predictedHeight = predictedWidth / aspectRatioDesktop();
    const imageOverlappingScreen = predictedHeight - windowSize.height;
    const isTooTall = isPortraitImage() && imageOverlappingScreen > 400;
    setPortraitTooTall(isTooTall);
  }, [windowSize]);

  const shouldFitHeight = portraitTooTall && isZoomed;
  const gutter = rectRef.current?.getBoundingClientRect().y || 0;
  const adjustedHeight = windowSize.height - gutter * 6;
  const adjustedWidth = adjustedHeight * aspectRatioDesktop();
  // const scale = adjustedWidth / predictedWidth;
  const heightState = shouldFitHeight ? style.cropperFitHeight : null;
  const alignState = shouldFitHeight ? style.positionerHasFitHeightItem : null;

  const portraitState = portraitTooTall ? style.positionerForPortrait : null;
  const cropperState = portraitTooTall ? style.cropperForPortrait : null;
  const iconState = isZoomed ? style.iconIsMinus : style.iconIsPlus;
  const iconHolderState =
    (isPortraitImage() && portraitTooTall) || (!isPortraitImage() && item.isZoomable)
      ? style.iconHolderVisible
      : null;
  const showCursorState =
    (isPortraitImage() && portraitTooTall) || (!isPortraitImage() && item.isZoomable)
      ? style.hideCursor
      : null;

  //is zoomable and not portrait 100vw

  const sizes = item.isZoomable && !isPortraitImage() ? '100vw' : '50vw';
  // console.log(item.media.image, item.isZoomable, sizes);
  return (
    <div
      onClick={onItemClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      ref={rectRef}
      className={`${style.positioner} ${showCursorState} ${alignState} ${portraitState}`}
    >
      <div className={`${style.iconHolder} ${iconHolderState}`}>
        <div ref={mouseFollowRef} className={`${style.icon} ${iconState}`}>
          <span className={`${style.iconLine} `}></span>
          <span className={style.iconLine}></span>
        </div>
      </div>
      <div
        ref={zoomRef}
        className={`${style.cropper} ${heightState} ${cropperState}`}
        style={
          shouldFitHeight
            ? {
                aspectRatio: `${item.media.image_width}/${item.media.image_height}`,
                width: `${adjustedWidth}px`,
                height: `${adjustedHeight}px`,
              }
            : undefined
        }
      >
        {item.media.type.desktop === 'squareTopRight' ||
        item.media.type.desktop === 'squareBottomLeft' ? (
          <MediaBlock
            media={item.media}
            // layout="responsive"
            layout="fill"
            objectFit="cover"
            block_type="media"
            sizes={sizes}
            priority={true}
          />
        ) : (
          <MediaBlock
            media={item.media}
            layout="fill"
            objectFit="cover"
            block_type="media"
            sizes={sizes}
            priority={true}
          />
        )}
      </div>
    </div>
  );
};
