import gsap from 'gsap/dist/gsap';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ChapterType } from '../../pages/chapter/[id]';
import { getCSSVar, getPathWithoutQuery, isMobileWidth, setCSSVar } from '../../utils/helpers';
import { widont } from '../../utils/typography';
import { ChapterMediaType, ChapterMediaItem } from '../chapterMediaItem/ChapterMediaItem';
import { CustomCursor } from '../customCursor/CustomCursor';
import { CustomMetaDataProps } from '../general/customMetaData/CustomMetaData';

import style from './chapter.module.scss';

type Props = {
  nextURL: string;
  prevURL: string;
  chapter: ChapterType;
  chapters: ChapterType[];
  // metaData: CustomMetaDataProps;
  forcedNumber?: number;
};

const Chapter = ({ chapter, nextURL, prevURL, forcedNumber, chapters = [] }: Props) => {
  const {
    showMenu,
    menuIsShowing,

    currentChapter,
    currentChapterPage,
    setCurrentChapter,
    setNextChapterURL,
    setPrevChapterURL,
    setChapters,
    hideUI,
  } = useAppContext();
  const [mediaIsZoomed, setMediaIsZoomed] = useState<boolean>(false);
  const [showCustomCursor, setShowCustomCursor] = useState<boolean>(false);
  const [mobileShowImages, setMobileShowImages] = useState<boolean>(!chapter.featured);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const pageNumberRef = useRef<HTMLDivElement>(null);
  // const [pauseImage, setPauseImage] = useState<boolean>(true);

  const router = useRouter();

  useEffect(() => {
    setNextChapterURL(nextURL);
    setPrevChapterURL(prevURL);
    setCurrentChapter(chapter);
    setChapters(chapters);
  }, []);

  useEffect(() => {
    //when slug changes
    //start listening for route changes within slug
    //cancel animation after first
    setCSSVar('--pauseDelay', `${getCSSVar('--pauseDelayMax')}s`);
  }, [chapter.slug]);

  useEffect(() => {
    // only update if the change in chapter relates to the current chapter slug
    // this is because we use cross fade animations and have 2 chapters on screen at 1 time
    // that why we have a local state per page
    const isCorrectChapter =
      getPathWithoutQuery(router.asPath) === `/chapter/${currentChapter?.slug}`;
    if (isCorrectChapter && currentChapter && currentChapter?.slug === chapter.slug) {
      setSelectedPage(currentChapterPage);
      const isLastPage =
        currentChapter.page_count > 1 && currentChapterPage === currentChapter.page_count - 1;
      // console.log(currentChapterPage, currentChapter.page_count - 1, isLastPage);
      if (currentChapterPage > 0 && !isLastPage) {
        setCSSVar('--pauseDelay', '0s');
      }
    }
  }, [currentChapterPage, currentChapter, router]);

  useEffect(() => {
    if (!chapter.featured) {
      setMobileShowImages(true);
    }
  }, [currentChapterPage]);

  useEffect(() => {
    if (hideUI) gsap.to(pageNumberRef.current, { autoAlpha: 0 });
    if (!hideUI) gsap.to(pageNumberRef.current, { autoAlpha: 1, delay: 0.5 });
  }, [hideUI]);

  const pageNumberState = menuIsShowing ? style.pageNumberHide : null;

  const showZoomCursor = (show: boolean, isZoomed: boolean) => {
    setShowCustomCursor(show);
    setMediaIsZoomed(isZoomed);
  };

  const onContentToggleClick = () => {
    setMobileShowImages(!mobileShowImages);
  };
  const { width } = useWindowSize();
  useEffect(() => {
    if (mobileShowImages && isMobileWidth(width)) {
      document.body.classList.add('u-bounce-remover');
    } else {
      document.body.classList.remove('u-bounce-remover');
    }
  }, [mobileShowImages, width]);

  const cursorState = showCustomCursor ? style.hideCursor : null;
  const mobileImageState = !mobileShowImages ? style.gridContentIsVisible : null;

  const toggleState = !mobileShowImages ? style.toggleActive : null;

  let offset = 0;
  const chapterIndex = chapters.findIndex((c) => c.slug === chapter.slug);
  const previousChapters = [...chapters].splice(0, chapterIndex);
  previousChapters.forEach((c) => {
    if (c.slug !== chapter.slug) offset += c.page_count;
  });
  const pageNumber = (offset + selectedPage).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  const showInfoToggle = !chapter.featured && chapter.pages[selectedPage].content.length > 0;

  return (
    <div className={`${style.page} ${cursorState}`}>
      {/* <div className={style.scroller}>x</div> */}
      <CustomCursor isZoomed={mediaIsZoomed} show={showCustomCursor} />
      <div className={style.container}>
        <div className={style.grid}>
          <div
            className={`${style.pageNumber} ${pageNumberState}`}
            style={{ transitionDelay: menuIsShowing ? '0s' : '0.5s' }}
          >
            <span ref={pageNumberRef}>{forcedNumber ? forcedNumber : pageNumber}</span>
          </div>
          <div className={`${style.gridImages} `}>
            <div className={`${style.gridImageSlider} js-global-pageTransitionHook__slide-images`}>
              {chapter.pages.map((item, index) => {
                const isActivePage = index === selectedPage;
                const imageState = isActivePage ? style.gridImageSlideSelected : null;
                return (
                  <div className={`${style.gridImageSlide} ${imageState}`} key={index}>
                    <div className={`${style.imageContainer}`}>
                      <ChapterMediaItem
                        backgroundColour={item.background_color}
                        chapterMedia={item.media_items}
                        showCustomCursor={showZoomCursor}
                        isActive={isActivePage}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={`${style.gridContent} ${mobileImageState} js-global-pageTransitionHook__slide-content`}
          >
            <div className={`${style.gridContentSlider}`}>
              {chapter.pages.map((item, index) => {
                const contentState = index === selectedPage ? style.gridContentSlideSelected : null;
                return (
                  <div className={`${style.gridContentSlide} ${contentState}`} key={index}>
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: widont(item.content),
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.titleContainer} js-desktop-title`}>
        <div className={`${style.titleGradient} `} />
        <h1 className={style.title} dangerouslySetInnerHTML={{ __html: chapter.title }} />
      </div>
      <div className={`${style.mobileInfoFooter} `}>
        {showInfoToggle && (
          <button
            role="button"
            onClick={onContentToggleClick}
            className={`${style.contentToggle} ${toggleState}`}
          >
            Info
          </button>
        )}
      </div>
    </div>
  );
};

export default Chapter;
